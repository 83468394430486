import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

  @Input() listWorkers: string[] = [];
  @Input() fullInfo: boolean = false;
  @Input() title: boolean = true;
  @Input() colmd: string = '3';
  @Input() titleText: string = 'Equipo especializado';
  @Input() extra = false;

  public classColMd = '';
  public isFullJumpLines = false;

  constructor() { }

  ngOnInit() {
    this.classColMd = 'col-lg-'+this.colmd;

    if(this.listWorkers.length == 5){
      this.isFullJumpLines = true;
    }
  }

}
