import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-psicoterapia-humanista',
  templateUrl: './subpage-psicoterapia-humanista.component.html',
  styleUrls: ['./subpage-psicoterapia-humanista.component.scss']
})
export class SubpagePsicoterapiaHumanistaComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
