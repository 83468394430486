import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-encuentros-sosteniendo-a-quien-sostiene',
  templateUrl: './subpage-encuentros-sosteniendo-a-quien-sostiene.component.html',
  styleUrls: ['./subpage-encuentros-sosteniendo-a-quien-sostiene.component.scss']
})
export class SubpageEncuentrosSosteniendoAQuienSostieneComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
    
  }

}
