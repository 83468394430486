import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-fixed',
  templateUrl: './navbar-fixed.component.html',
  styleUrls: ['./navbar-fixed.component.scss']
})
export class NavbarFixedComponent implements OnInit {

  route: string;
  activeFixed: boolean;
  isMobile: boolean = false;
  openMobile: boolean = false;

  lastRoute = null;

  constructor(location: Location, router: Router) { 
    router.events.subscribe((val) => {
      this.closeMobileMenu();
      if(location.path() != ''){
        this.route = location.path();
      } else {
        this.route = 'Home'
      }      
      this.lastRoute = this.route;
    });
    this.activeFixed = false;
  }

  ngOnInit() {
    this.updateIsMobile();
  }

  @HostListener('window:scroll', ['$event']) 
  checkStrollWindow(event) {  
    this.updateIsMobile();
    if(this.isMobile) {
      this.activeFixed = true;
    } else {      
      if(window.pageYOffset > 200){
        this.activeFixed = true;
      } else {
        this.activeFixed = false;
      }
    }
    
  }

  @HostListener('window:resize', ['$event']) 
  checkSizeWindow(event) {  
    this.updateIsMobile();   
    if(this.isMobile) {
      this.activeFixed = true;
    } else {      
      if(window.pageYOffset > 200){
        this.activeFixed = true;
      } else {
        this.activeFixed = false;
      }
    } 
  }

  updateIsMobile(){
    if(window.innerWidth <= 800){
      this.isMobile = true;
      this.activeFixed = true;
    } else {
      this.isMobile = false;
      this.activeFixed = false;
      this.openMobile = true;
    }

   /* console.log(this.isMobile ,
      this.activeFixed ,
      this.openMobile);*/
    
    
  }

  openMobileFunction(){
    this.openMobile = !this.openMobile;
  }

  closeMobileMenu(){
    console.log(this.lastRoute,this.route);
    
    if(this.isMobile/*this.lastRoute != this.route*/){
      this.openMobile = false;
    }
    
  }

}
