import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-asesoramiento',
  templateUrl: './page-asesoramiento.component.html',
  styleUrls: ['./page-asesoramiento.component.scss']
})
export class PageAsesoramientoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
