import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss']
})
export class PageIndexComponent implements OnInit {

  lastItemShow = 1;
  lastLeftAnimation = 0;
  lockedAnimation = false;

  public xDown = null;                                                        
  public yDown = null;

  showTest = 'Nada';

  constructor() { 
  }

  ngOnInit() {
    window.scroll(0,0);

    let there = this;

    document.getElementById("parallax-testimonial-selector").addEventListener('touchstart', function(){
      there.handleTouchStart(event,there)
    }, false);      
    document.getElementById("parallax-testimonial-selector").addEventListener('touchmove', function(){
      there.handleTouchMove(event,there)
    }, false);   
  }


  moveSliderLeft(){
    let isMove = true;
    let newAnimationLeft = this.lastLeftAnimation - 100;
    if(newAnimationLeft < -200){
      newAnimationLeft = 200;
      isMove = false;
    }
    if(isMove && !this.lockedAnimation){
      this.lastItemShow ++;
      this.animation(newAnimationLeft);
    }
  }

  moveSliderRight(){
    let isMove = true;
    let newAnimationLeft = this.lastLeftAnimation + 100;
    if(newAnimationLeft > 0){
      newAnimationLeft = 0;
      isMove = false;
    }
    if(isMove && !this.lockedAnimation){
      this.lastItemShow --;
      this.animation(newAnimationLeft);
    }
  }

  moveDirect(slideNumber){
    let isMove = false;
    let newAnimationLeft = 0;
    console.log(slideNumber , this.lastItemShow, this.lastLeftAnimation);
    
    if(slideNumber < this.lastItemShow){
      newAnimationLeft = this.lastLeftAnimation + ((this.lastItemShow - slideNumber) * 100);
      isMove = true;
    }
    if(slideNumber > this.lastItemShow){
      newAnimationLeft = this.lastLeftAnimation - ((slideNumber - this.lastItemShow) * 100);
      isMove = true;
    }   
    if(isMove && !this.lockedAnimation){
      this.lastItemShow = slideNumber;
      this.animation(newAnimationLeft);
    }
  }

  animation(newAnimLeft){
    console.log("Entra en animation");
    
    let there = this;
    this.lockedAnimation = true;
    let intValT = null;
    let elem = document.getElementById("box-testimonial-slider");
    let pos = 0;
    clearInterval(intValT);
    intValT = setInterval(function(){
      //console.log(there.lastLeftAnimation,newAnimLeft);
      if (there.lastLeftAnimation == newAnimLeft) {
        clearInterval(intValT);
        there.lockedAnimation = false;
      } else {
        if(there.lastLeftAnimation > newAnimLeft){
          there.lastLeftAnimation -= 5;
        } else {
          there.lastLeftAnimation += 5;
        }
        elem.style.left = there.lastLeftAnimation + '%';        
      }
    }, 10);    
  }


  
  getTouches(evt) {
    return evt.touches ||             // browser API
           evt.originalEvent.touches; // jQuery
  }                                                     
  
  handleTouchStart(evt,there) {
      let retTouch = there.getTouches(evt);
      const firstTouch = retTouch[0];                                      
      there.xDown = firstTouch.clientX;                                      
      there.yDown = firstTouch.clientY;                                      
  };                                                
  
  handleTouchMove(evt,there) {
    if ( ! there.xDown || ! there.yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;

    var xDiff = there.xDown - xUp;
    var yDiff = there.yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
            /* left swipe */ 
            there.moveSliderLeft();
            
        } else {
            /* right swipe */
            there.moveSliderRight();
        }                       
    } else {
        if ( yDiff > 0 ) {
            /* up swipe */ 
        } else { 
            /* down swipe */
        }                                                                 
    }
    /* reset values */
    there.xDown = null;
    there.yDown = null;                                             
};
}
