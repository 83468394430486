import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageIndexComponent } from './pages/page-index/page-index.component';
import { APP_ROUTING } from './app.routes';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageServiciosComponent } from './pages/page-servicios/page-servicios.component';
import { PageAsesoramientoComponent } from './pages/page-asesoramiento/page-asesoramiento.component';
import { PageEncuentrosComponent } from './pages/page-encuentros/page-encuentros.component';
import { PageContactoComponent } from './pages/page-contacto/page-contacto.component';
import { UnderlineCustomComponent } from './components/underline-custom/underline-custom.component';
import { NavbarFixedComponent } from './components/navbar-fixed/navbar-fixed.component';
import { PageEquipoYTarifasComponent } from './pages/page-equipo-y-tarifas/page-equipo-y-tarifas.component';
import { SubpageTempServicioInfoComponent } from './pages/subpage-temp-servicio-info/subpage-temp-servicio-info.component';
import { TreeLayoutComponent } from './layouts/tree-layout/tree-layout.component';
import { TreePageComponent } from './pages/tree-page/tree-page.component';
import { PageEquipoComponent } from './pages/page-equipo/page-equipo.component';
import { PageTarifasComponent } from './pages/page-tarifas/page-tarifas.component';
import { SubpagePsicologiaPerinatalComponent } from './pages/subpage-psicologia-perinatal/subpage-psicologia-perinatal.component';
import { SubpageAcompanamientoEnGrupoComponent } from './pages/subpage-acompanamiento-en-grupo/subpage-acompanamiento-en-grupo.component';
import { SubpagePsicoterapiaHumanistaComponent } from './pages/subpage-psicoterapia-humanista/subpage-psicoterapia-humanista.component';
import { SubpageConsultoriaDeLactanciaComponent } from './pages/subpage-consultoria-de-lactancia/subpage-consultoria-de-lactancia.component';
import { SubpageEncuentrosDeLactanciaComponent } from './pages/subpage-encuentros-de-lactancia/subpage-encuentros-de-lactancia.component';
import { SubpageLactanciaEnDueloComponent } from './pages/subpage-lactancia-en-duelo/subpage-lactancia-en-duelo.component';
import { SubpagePedagogiaDelInstitutoPiklerComponent } from './pages/subpage-pedagogia-del-instituto-pikler/subpage-pedagogia-del-instituto-pikler.component';
import { SubpageAsesoramientoPedagogicoComponent } from './pages/subpage-asesoramiento-pedagogico/subpage-asesoramiento-pedagogico.component';
import { SubpageObservacionEnElHogarComponent } from './pages/subpage-observacion-en-el-hogar/subpage-observacion-en-el-hogar.component';
import { SubpageAcondicionamientoDelEspacioComponent } from './pages/subpage-acondicionamiento-del-espacio/subpage-acondicionamiento-del-espacio.component';
import { SubpageAsesoramientoPsicoPedagogicoPermanenteAlEducadorComponent } from './pages/subpage-asesoramiento-psico-pedagogico-permanente-al-educador/subpage-asesoramiento-psico-pedagogico-permanente-al-educador.component';
import { SubpageObservacionYReflexionEnLaEscuelaInfantilComponent } from './pages/subpage-observacion-y-reflexion-en-la-escuela-infantil/subpage-observacion-y-reflexion-en-la-escuela-infantil.component';
import { SubpageLaMiradaDelEducadorComponent } from './pages/subpage-la-mirada-del-educador/subpage-la-mirada-del-educador.component';
import { SubpageGrupoBurbujaComponent } from './pages/subpage-grupo-burbuja/subpage-grupo-burbuja.component';
import { SubpageEscuelaCompartidaComponent } from './pages/subpage-escuela-compartida/subpage-escuela-compartida.component';
import { SubpageEncuentrosSosteniendoAQuienSostieneComponent } from './pages/subpage-encuentros-sosteniendo-a-quien-sostiene/subpage-encuentros-sosteniendo-a-quien-sostiene.component';
import { SubpageEncuentrosElConocerYElSentirComponent } from './pages/subpage-encuentros-el-conocer-y-el-sentir/subpage-encuentros-el-conocer-y-el-sentir.component';
import { SubpageEncuentrosEmpoderarseParaVolverComponent } from './pages/subpage-encuentros-empoderarse-para-volver/subpage-encuentros-empoderarse-para-volver.component';
import { SubpageEncuentrosDesatandoNudosComponent } from './pages/subpage-encuentros-desatando-nudos/subpage-encuentros-desatando-nudos.component';
import { PropiedadesServicioComponent } from './components/propiedades-servicio/propiedades-servicio.component';
import { GroupWorkerComponent } from './components/group-worker/group-worker.component';
import { GroupListComponent } from './components/group-list/group-list.component';
import { SubpageYogaAshtangaVinyasaComponent } from './pages/subpage-yoga-ashtanga-vinyasa/subpage-yoga-ashtanga-vinyasa.component';
import { FormsModule } from '@angular/forms';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { HttpClientModule } from '@angular/common/http';
import { ContactWorkerComponent } from './pages/contact-worker/contact-worker.component';
import { PageAvisoLegalComponent } from './pages/page-aviso-legal/page-aviso-legal.component';
import { PagePoliticaPrivacidadComponent } from './pages/page-politica-privacidad/page-politica-privacidad.component';
import { PagePoliticaCookiesComponent } from './pages/page-politica-cookies/page-politica-cookies.component';
import { TreeSubscribePageComponent } from './pages/tree-subscribe-page/tree-subscribe-page.component';

const RECAPTCHA_OPTION = {
  invisible: {
    sitekey: '6LdDgEYbAAAAAHsUzIDwyWaTC8jaEvIts_ExB98w', 
  },
  normal: {
      sitekey: '6LdDgEYbAAAAAHsUzIDwyWaTC8jaEvIts_ExB98w', 
  },
  language: 'es'
}

interface IRecaptchaOption {
  sitekey: string;
  theme?: string;
  type?: string;
  tabindex?: number;
  badge?: string;
}


@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    PageIndexComponent,
    NavbarComponent,
    PageServiciosComponent,
    PageAsesoramientoComponent,
    PageEncuentrosComponent,
    PageContactoComponent,
    UnderlineCustomComponent,
    NavbarFixedComponent,
    PageEquipoYTarifasComponent,
    SubpageTempServicioInfoComponent,
    TreeLayoutComponent,
    TreePageComponent,
    PageEquipoComponent,
    PageTarifasComponent,
    SubpagePsicologiaPerinatalComponent,
    SubpageAcompanamientoEnGrupoComponent,
    SubpagePsicoterapiaHumanistaComponent,
    SubpageConsultoriaDeLactanciaComponent,
    SubpageEncuentrosDeLactanciaComponent,
    SubpageLactanciaEnDueloComponent,
    SubpagePedagogiaDelInstitutoPiklerComponent,
    SubpageAsesoramientoPedagogicoComponent,
    SubpageObservacionEnElHogarComponent,
    SubpageAcondicionamientoDelEspacioComponent,
    SubpageAsesoramientoPsicoPedagogicoPermanenteAlEducadorComponent,
    SubpageObservacionYReflexionEnLaEscuelaInfantilComponent,
    SubpageLaMiradaDelEducadorComponent,
    SubpageGrupoBurbujaComponent,
    SubpageEscuelaCompartidaComponent,
    SubpageEncuentrosSosteniendoAQuienSostieneComponent,
    SubpageEncuentrosElConocerYElSentirComponent,
    SubpageEncuentrosEmpoderarseParaVolverComponent,
    SubpageEncuentrosDesatandoNudosComponent,
    PropiedadesServicioComponent,
    GroupWorkerComponent,
    GroupListComponent,
    SubpageYogaAshtangaVinyasaComponent,
    ContactWorkerComponent,
    PageAvisoLegalComponent,
    PagePoliticaPrivacidadComponent,
    PagePoliticaCookiesComponent,
    TreeSubscribePageComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReCaptchaModule.forRoot(RECAPTCHA_OPTION),
    HttpClientModule,
    APP_ROUTING
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
