import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-politica-privacidad',
  templateUrl: './page-politica-privacidad.component.html',
  styleUrls: ['./page-politica-privacidad.component.scss']
})
export class PagePoliticaPrivacidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
