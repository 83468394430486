import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PageIndexComponent } from './pages/page-index/page-index.component';
import { PageAsesoramientoComponent } from './pages/page-asesoramiento/page-asesoramiento.component';
import { PageContactoComponent } from './pages/page-contacto/page-contacto.component';
import { PageEncuentrosComponent } from './pages/page-encuentros/page-encuentros.component';
import { PageServiciosComponent } from './pages/page-servicios/page-servicios.component';
import { SubpageTempServicioInfoComponent } from './pages/subpage-temp-servicio-info/subpage-temp-servicio-info.component';
import { TreeLayoutComponent } from './layouts/tree-layout/tree-layout.component';
import { TreePageComponent } from './pages/tree-page/tree-page.component';
import { PageEquipoComponent } from './pages/page-equipo/page-equipo.component';
import { PageTarifasComponent } from './pages/page-tarifas/page-tarifas.component';
import { SubpageAcompanamientoEnGrupoComponent } from './pages/subpage-acompanamiento-en-grupo/subpage-acompanamiento-en-grupo.component';
import { SubpageAcondicionamientoDelEspacioComponent } from './pages/subpage-acondicionamiento-del-espacio/subpage-acondicionamiento-del-espacio.component';
import { SubpageAsesoramientoPedagogicoComponent } from './pages/subpage-asesoramiento-pedagogico/subpage-asesoramiento-pedagogico.component';
import { SubpageAsesoramientoPsicoPedagogicoPermanenteAlEducadorComponent } from './pages/subpage-asesoramiento-psico-pedagogico-permanente-al-educador/subpage-asesoramiento-psico-pedagogico-permanente-al-educador.component';
import { SubpageConsultoriaDeLactanciaComponent } from './pages/subpage-consultoria-de-lactancia/subpage-consultoria-de-lactancia.component';
import { SubpageEncuentrosDeLactanciaComponent } from './pages/subpage-encuentros-de-lactancia/subpage-encuentros-de-lactancia.component';
import { SubpageEscuelaCompartidaComponent } from './pages/subpage-escuela-compartida/subpage-escuela-compartida.component';
import { SubpageGrupoBurbujaComponent } from './pages/subpage-grupo-burbuja/subpage-grupo-burbuja.component';
import { SubpageLaMiradaDelEducadorComponent } from './pages/subpage-la-mirada-del-educador/subpage-la-mirada-del-educador.component';
import { SubpageLactanciaEnDueloComponent } from './pages/subpage-lactancia-en-duelo/subpage-lactancia-en-duelo.component';
import { SubpageObservacionEnElHogarComponent } from './pages/subpage-observacion-en-el-hogar/subpage-observacion-en-el-hogar.component';
import { SubpageObservacionYReflexionEnLaEscuelaInfantilComponent } from './pages/subpage-observacion-y-reflexion-en-la-escuela-infantil/subpage-observacion-y-reflexion-en-la-escuela-infantil.component';
import { SubpagePedagogiaDelInstitutoPiklerComponent } from './pages/subpage-pedagogia-del-instituto-pikler/subpage-pedagogia-del-instituto-pikler.component';
import { SubpagePsicologiaPerinatalComponent } from './pages/subpage-psicologia-perinatal/subpage-psicologia-perinatal.component';
import { SubpagePsicoterapiaHumanistaComponent } from './pages/subpage-psicoterapia-humanista/subpage-psicoterapia-humanista.component';
import { SubpageEncuentrosSosteniendoAQuienSostieneComponent } from './pages/subpage-encuentros-sosteniendo-a-quien-sostiene/subpage-encuentros-sosteniendo-a-quien-sostiene.component';
import { SubpageEncuentrosElConocerYElSentirComponent } from './pages/subpage-encuentros-el-conocer-y-el-sentir/subpage-encuentros-el-conocer-y-el-sentir.component';
import { SubpageEncuentrosEmpoderarseParaVolverComponent } from './pages/subpage-encuentros-empoderarse-para-volver/subpage-encuentros-empoderarse-para-volver.component';
import { SubpageEncuentrosDesatandoNudosComponent } from './pages/subpage-encuentros-desatando-nudos/subpage-encuentros-desatando-nudos.component';
import { SubpageYogaAshtangaVinyasaComponent } from './pages/subpage-yoga-ashtanga-vinyasa/subpage-yoga-ashtanga-vinyasa.component';
import { ContactWorkerComponent } from './pages/contact-worker/contact-worker.component';
import { PageAvisoLegalComponent } from './pages/page-aviso-legal/page-aviso-legal.component';
import { PagePoliticaCookiesComponent } from './pages/page-politica-cookies/page-politica-cookies.component';
import { PagePoliticaPrivacidadComponent } from './pages/page-politica-privacidad/page-politica-privacidad.component';
import { TreeSubscribePageComponent } from './pages/tree-subscribe-page/tree-subscribe-page.component';

const APP_ROUTES: Routes = [
{
    path: '',
    component: MainLayoutComponent,
    children: [
        {
            path: '',
            component: PageIndexComponent,
        },
        {
            path: 'asesoramiento',
            component: PageAsesoramientoComponent,
        },
        {
            path: 'contacto',
            component: PageContactoComponent,
        },
        {
            path: 'encuentros',
            component: PageEncuentrosComponent,
        },
        {
            path: 'encuentros/:id',
            component: PageEncuentrosComponent,
        },
        {
            path: 'servicios',
            component: PageServiciosComponent,
        },
        {
            path: 'servicios/:id',
            component: PageServiciosComponent,
        },
        {
            path: 'contacto/:id',
            component: ContactWorkerComponent,
        },
        {
            path: 'tarifas',
            component: PageTarifasComponent,
        },
        {
            path: 'equipo',
            component: PageEquipoComponent,
        },
        {
            path: 'info-servicio/:title',
            component: SubpageTempServicioInfoComponent,
        },



        {
            path: 'acompanamiento-sensible-a-la-infancia',
            component: SubpageAcompanamientoEnGrupoComponent
        },
        {
            path: 'acondicionamiento-del-espacio',
            component: SubpageAcondicionamientoDelEspacioComponent
        },
        {
            path: 'asesoramiento-pedagogico',
            component: SubpageAsesoramientoPedagogicoComponent
        },
        {
            path: 'asesoramiento-psico-pedagogico-permanente-al-educador',
            component: SubpageAsesoramientoPsicoPedagogicoPermanenteAlEducadorComponent
        },
        {
            path: 'acompanamiento-a-la-lactancia',
            component: SubpageConsultoriaDeLactanciaComponent
        },
        {
            path: 'encuentros-de-lactancia',
            component: SubpageEncuentrosDeLactanciaComponent
        },
        {
            path: 'espacio-de-familia',
            component: SubpageEscuelaCompartidaComponent
        },
        {
            path: 'grupo-caracubo',
            component: SubpageGrupoBurbujaComponent
        },
        {
            path: 'la-mirada-del-educador',
            component: SubpageLaMiradaDelEducadorComponent
        },
        {
            path: 'lactancia-en-duelo',
            component: SubpageLactanciaEnDueloComponent
        },
        {
            path: 'observacion-en-el-hogar',
            component: SubpageObservacionEnElHogarComponent
        },
        {
            path: 'observacion-y-reflexion-en-la-escuela-infantil',
            component: SubpageObservacionYReflexionEnLaEscuelaInfantilComponent
        },
        {
            path: 'acompanamiento-a-profesionales',
            component: SubpagePedagogiaDelInstitutoPiklerComponent
        },
        {
            path: 'psicologia-perinatal',
            component: SubpagePsicologiaPerinatalComponent
        },
        {
            path: 'psicoterapia-humanista',
            component: SubpagePsicoterapiaHumanistaComponent
        },
        {
            path: 'sosteniendo-a-quien-sostiene',
            component: SubpageEncuentrosSosteniendoAQuienSostieneComponent
        },
        {
            path: 'el-conocer-y-el-sentir',
            component: SubpageEncuentrosElConocerYElSentirComponent
        },
        {
            path: 'empoderarse-para-volver',
            component: SubpageEncuentrosEmpoderarseParaVolverComponent
        },
        {
            path: 'desatando-nudos',
            component: SubpageEncuentrosDesatandoNudosComponent
        },
        {
            path: 'yoga-ashtanga-vinyasa',
            component: SubpageYogaAshtangaVinyasaComponent
        },
        {
            path: 'legal/aviso-legal',
            component: PageAvisoLegalComponent
        },
        {
            path: 'legal/politica-de-cookies',
            component: PagePoliticaCookiesComponent
        },
        {
            path: 'legal/politica-de-privacidad',
            component: PagePoliticaPrivacidadComponent
        }
        
    ]
},
{
    path: 'tree',
    component: TreeLayoutComponent,
    children: [
        {
            path: '',
            component: TreePageComponent,
        },
        {
            path: 'promocion',
            component: TreeSubscribePageComponent
        }
    ]
}


];


export const APP_ROUTING = RouterModule.forChild(APP_ROUTES);