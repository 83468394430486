import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-grupo-burbuja',
  templateUrl: './subpage-grupo-burbuja.component.html',
  styleUrls: ['./subpage-grupo-burbuja.component.scss']
})
export class SubpageGrupoBurbujaComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
