import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-encuentros',
  templateUrl: './page-encuentros.component.html',
  styleUrls: ['./page-encuentros.component.scss']
})
export class PageEncuentrosComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    window.scroll(0,0);

    this.route.paramMap.subscribe(params => {
      window.scroll(0,0);
      let paramId = params.get("id");
      if(paramId == 'emocion_cuerpo_y_raices'){
        let element = document.getElementById('scroll-emocion_cuerpo_y_raices').getBoundingClientRect().top;
        if(typeof element !== 'undefined') {
          window.scroll(0,element);
        }
        //this.paramServiceId = paramId;
      } 
      if(paramId == 'acompanamiento_a_la_infancia'){
        let element = document.getElementById('scroll-acompanamiento_a_la_infancia').getBoundingClientRect().top;
        if(typeof element !== 'undefined') {
          window.scroll(0,element);
        }
        //this.paramServiceId = paramId;
      } 
      if(paramId == 'identidad_y_movimiento_libre'){
        let element = document.getElementById('scroll-identidad_y_movimiento_libre').getBoundingClientRect().top;
        if(typeof element !== 'undefined') {
          window.scroll(0,element);
        }
        //this.paramServiceId = paramId;
      }

      if(paramId == 'yoga_activo'){
        let element = document.getElementById('scroll-yoga_activo').getBoundingClientRect().top;
        if(typeof element !== 'undefined') {
          window.scroll(0,element);
        }
        //this.paramServiceId = paramId;
      }
    })

  
  }

}
