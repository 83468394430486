import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-encuentros-desatando-nudos',
  templateUrl: './subpage-encuentros-desatando-nudos.component.html',
  styleUrls: ['./subpage-encuentros-desatando-nudos.component.scss']
})
export class SubpageEncuentrosDesatandoNudosComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
