import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-acondicionamiento-del-espacio',
  templateUrl: './subpage-acondicionamiento-del-espacio.component.html',
  styleUrls: ['./subpage-acondicionamiento-del-espacio.component.scss']
})
export class SubpageAcondicionamientoDelEspacioComponent implements OnInit {

  constructor() {
    window.scroll(0,0);
   }

  ngOnInit() {
  }

}
