import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-aviso-legal',
  templateUrl: './page-aviso-legal.component.html',
  styleUrls: ['./page-aviso-legal.component.scss']
})
export class PageAvisoLegalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
