import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-observacion-en-el-hogar',
  templateUrl: './subpage-observacion-en-el-hogar.component.html',
  styleUrls: ['./subpage-observacion-en-el-hogar.component.scss']
})
export class SubpageObservacionEnElHogarComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
