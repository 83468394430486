import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-politica-cookies',
  templateUrl: './page-politica-cookies.component.html',
  styleUrls: ['./page-politica-cookies.component.scss']
})
export class PagePoliticaCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
