import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-escuela-compartida',
  templateUrl: './subpage-escuela-compartida.component.html',
  styleUrls: ['./subpage-escuela-compartida.component.scss']
})
export class SubpageEscuelaCompartidaComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
