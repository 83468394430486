import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  route: string;
  /*countersMouseEnter: any = {
    'psicologia': 0,
    'pedagogias': 0,
    'lactancia': 0,
    'encuentro': 0
  }*/

  constructor(location: Location, router: Router) { 
    router.events.subscribe((val) => {
      //this.hideSubMenus();
      if(location.path() != ''){
        this.route = location.path();
      } else {
        this.route = 'Home'
      }
      
    });
  }

  hideSubMenus(){
    let subMenus = document.getElementsByClassName("main-menu-sub-menu-list") as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < subMenus.length; i++) {
      let subMenuElem = subMenus[i];
      subMenuElem.style.display = "none";
    }
  }

  /*addCounter(keyVal){
    this.countersMouseEnter[keyVal] += 1;

    console.log(this.countersMouseEnter);
  }

  removeCounter(keyVal){
    this.countersMouseEnter[keyVal] -= 1;

    console.log(this.countersMouseEnter);
  }*/

  ngOnInit() {
    let there = this;
    /*let psicologia = document.getElementById('float-global-sub-menu-psicologia');
    psicologia.addEventListener("mouseenter", function( event ) {
      there.addCounter('psicologia');
      there.timeHideSubmenu();
    }, false);
    psicologia.addEventListener("mouseleave", function( event ) {
      there.removeCounter('psicologia');
      there.timeHideSubmenu();
    }, false);

    let pedagogias = document.getElementById('float-global-sub-menu-pedagogias');
    pedagogias.addEventListener("mouseenter", function( event ) {
      there.addCounter('pedagogias');
      there.timeHideSubmenu();
    }, false);
    pedagogias.addEventListener("mouseleave", function( event ) {
      there.removeCounter('pedagogias');
      there.timeHideSubmenu();
    }, false);

    let lactancia = document.getElementById('float-global-sub-menu-lactancia');
    lactancia.addEventListener("mouseenter", function( event ) {
      there.addCounter('lactancia');
      there.timeHideSubmenu();
    }, false);
    lactancia.addEventListener("mouseleave", function( event ) {
      there.removeCounter('lactancia');
      there.timeHideSubmenu();
    }, false);

    let encuentro = document.getElementById('float-global-sub-menu-encuentro');
    encuentro.addEventListener("mouseenter", function( event ) {
      there.addCounter('encuentro');
      there.timeHideSubmenu();
    }, false);
    encuentro.addEventListener("mouseleave", function( event ) {
      there.removeCounter('encuentro');
      there.timeHideSubmenu();
    }, false);*/

    
  }

  /*timeHideSubmenu(){
    setTimeout(() => {
      let classList = document.getElementsByClassName("float-global-sub-menu") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < classList.length; i++) {
        let classElem = classList[i];
        let id = classElem.id.substr(22);
        if(this.countersMouseEnter[id] == 0){
          classElem.style.display = "none";
        }
      }
    }, 1500);
  }*/

  /*reset_counters(){
    this.countersMouseEnter = {
      'psicologia': 0,
      'pedagogias': 0,
      'lactancia': 0,
      'encuentro': 0
    }

    let classList = document.getElementsByClassName("float-global-sub-menu") as HTMLCollectionOf<HTMLElement>;
      for (let i = 0; i < classList.length; i++) {
        let classElem = classList[i];
          classElem.style.display = "none";
      }
  }*/

  /*submenu_hide(keyVal){
    this.removeCounter(keyVal);
    this.timeHideSubmenu();
  }*/

  /*submenu_load(keyVal){
    let parentElem = document.getElementById('menu-li-s-'+keyVal);
    let menuElem = document.getElementById('float-global-sub-menu-'+keyVal);
    this.submenu_hide('');
    if(menuElem != null && parentElem != null)
    {
      this.reset_counters();
      this.addCounter(keyVal);

      menuElem.style.display = "block";

      let offSetParent = this.getOffset(parentElem);
      menuElem.style.left = offSetParent.left + "px";
      menuElem.style.top = (offSetParent.top + 40) + "px";
      console.log(offSetParent.top);
      
    }   
    this.timeHideSubmenu();

  }*/

  /*getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }*/

  

}
