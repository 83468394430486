import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-encuentros-de-lactancia',
  templateUrl: './subpage-encuentros-de-lactancia.component.html',
  styleUrls: ['./subpage-encuentros-de-lactancia.component.scss']
})
export class SubpageEncuentrosDeLactanciaComponent implements OnInit {

  constructor() { 
    window.scroll(0,0);
    
  }

  ngOnInit() {
  }

}
