import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-worker',
  templateUrl: './group-worker.component.html',
  styleUrls: ['./group-worker.component.scss']
})
export class GroupWorkerComponent implements OnInit {

  @Input() worker = '';
  @Input() full = true;
  @Input() extra = false;

  public data = {
    'gabriela': {
      nombre: 'Gabriela H. López Martín',
      img: 'assets/img/equipo/gabriela.jpg',
      carrera: 'Psicóloga con nº col: CL-05315',
      titulos: [],//['Colegiada 5315'],
      extra: '',
      url: '/contacto/gabriela',
      descripcion: 'Formación en terapia Gestalt y Corporal.<br>Pedagoga Pikler en curso en el Instituto Pikler-Lóczy de Budapest.<br>Coordinadora, formadora y Asesora pedagógica de la pequeña infancia, equipos educativos y familias.<br>MTC: Dietética, Salud y Bienestar de las mujeres.<br>Yoga Ashtanga Vinyasa'
    },
    'aurora': {
      nombre: 'Aurora González Martín',
      img: 'assets/img/equipo/aurora.jpg',
      carrera: 'Trabajadora Social Perinatal.',//Trabajadora Social 
      titulos: [],
      extra: 'Experta en Lactancia Materna',
      url: '/contacto/aurora',
      descripcion: 'Auxiliar de pediatría.<br>Experta en prácticas de Lactancia Materna avanzadas con la Universidad Ramón Llull.<br>Educadora y Asesora familiar en centros infantiles desde la mirada Pikleriana.<br>Formándome como Asesora en Duelo Gestacional y Perinatal con la Escuela Stillbirth Support.<br>Técnico Superior en Educación Infantil, en curso.'
    },
    'zoraida': {
      nombre: 'Zoraida Del Paso Sánchez',
      img: 'assets/img/equipo/zoraida.jpg',
      carrera: 'Pedagoga. Colegiada 1284-75129762',
      titulos: [],//['Colegiada 1284-75129762'],
      extra: '',
      url: '/contacto/zoraida',
      descripcion: 'Pedagoga Pikler en curso en el Instituto Pikler-Lóczy de Budapest.<br>Coordinadora, formadora y Asesora pedagógica de la pequeña infancia, equipos educativos y familias.<br>Experta en TDAH y otros trastornos del aprendizaje, trastornos del habla y lenguaje.<br>Experta en BLW y alimentación infantil.<br>Educadora sexual.'
    },
    'elena': {
      nombre: 'Elena Pacheco de Bonrsotro',
      img: 'assets/img/equipo/elena.jpg',
      carrera: 'Maestra y Psicopedagoga',
      titulos: [],
      extra: '',
      url: '/contacto/elena',
      descripcion: 'Formación en terapia Gestalt<br>Ponente en cursos de formación del profesorado sobre  acompañamiento emocional<br>Integrante del grupo “Espacio de vuelta” : propuesta de acompañamiento emocional en la escuela '
    },
    //?????????????
    /*'esther': {
      nombre: 'Esther Manso Martín',
      img: 'assets/img/equipo/ester.jpg',
      carrera: 'Psicóloga, nº col: CL-04273',
      titulos: [],
      extra: '',
      url: '/contacto/esther',
      descripcion: 'Con habilitación de psicología sanitaria.<br>Miembro adherente de la AETG.<br> Psicopedagoga'
    }*/
  };

  constructor() { }

  ngOnInit() {
  }

}
