import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-equipo-y-tarifas',
  templateUrl: './page-equipo-y-tarifas.component.html',
  styleUrls: ['./page-equipo-y-tarifas.component.scss']
})
export class PageEquipoYTarifasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
