import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-underline-custom',
  templateUrl: './underline-custom.component.html',
  styleUrls: ['./underline-custom.component.scss']
})
export class UnderlineCustomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
