import { Component, OnInit } from '@angular/core';
import { ReCaptchaService } from 'angular-recaptcha3';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-tree-subscribe-page',
  templateUrl: './tree-subscribe-page.component.html',
  styleUrls: ['./tree-subscribe-page.component.scss']
})
export class TreeSubscribePageComponent implements OnInit {

  public formValuesPromo: {
    name: string,
    email: string,
    encuesta1: string,
    encuesta2: string,
    encuesta3: string,
  } = {
    name: '',
    email: '',
    encuesta1: '',
    encuesta2: '',
    encuesta3: ''
  };
  
  public vClass = 'error'; // success | error
  public vShow = false;
  public vMessage = '';
  public checkPromoLegal = false;
  public errorEmptyInputsPromo = false;
  public successSendPromoForm = false;

  constructor(
    private recaptchaService: ReCaptchaService,
    private _http: HttpClient
  ) { }

  ngOnInit() {
    window.scroll(0,0);
  }

  onCaptchaResponse(event){
    //console.log("onCaptcha",event);    
  }

  sendTokenToBackend(token){
    //console.log(token);
  }


  validateFormPromo(): boolean{
    let result = false;
    if(
      this.formValuesPromo.email != ''
      && this.formValuesPromo.name != ''
      && this.formValuesPromo.encuesta1 != ''
      && this.formValuesPromo.encuesta2 != ''
      && this.formValuesPromo.encuesta3 != ''
    ){
      if(this.checkPromoLegal){
        result = true;
      }
    }
    
    return result;
  }

  clearAlert(){
    this.vClass = 'error';
    this.vMessage = '';
    this.vShow = false;
  }


  sendFormPromo(){    
    if( this.validateFormPromo() ){
      this.errorEmptyInputsPromo = false;
      this.recaptchaService.execute({action: 'sendFormPromo'}).then(token => {
        this.sendTokenToBackend(token);
        this.finalSend(this.formValuesPromo,token,'sendFormPromo');
      });
    } else {
      this.errorEmptyInputsPromo = true;
    }
  }

  finalSend(params,token,action){
    let finalParams = {};
    finalParams['token'] = token;
    finalParams['params'] = params;
    finalParams['action'] = action;


    let paramsHttp = new HttpParams();
    paramsHttp = paramsHttp.append('params', JSON.stringify(finalParams));
    let headers = new HttpHeaders();

    let resultError = true;
    let resultMessage = 'Ocurrió un error, inténtelo más tarde.';
    this._http.request(
      'post', 'https://api.kosmoraices.com/form_submit.php',
      { body: paramsHttp, headers: headers, withCredentials: true })
      .subscribe( (response: any) => {
        console.log(response);
        if(typeof response !== 'undefined'){
          if(typeof response.error !== 'undefined'){
            if(!response.error){
              resultError = false;
              resultMessage = 'Mensaje enviado correctamente.';
              this.successSendPromoForm = true;
              this.formValuesPromo = {
                name: '',
                email: '',
                encuesta1: '',
                encuesta2: '',
                encuesta3: ''
              };
            } else {
              if(typeof response.error_name !== 'undefined'){
                  switch(response.error_name){
                    
                    case 'empty_params':
                      resultMessage = 'Se deben rellenar todos los campos.';
                    break;
                    case 'not_valid_params':
                    case 'empty_params_system':
                    case 'empty_form_type':
                    case 'not_sent':
                      resultMessage = 'Ocurrió un error, inténtelo más tarde.';

                    break;
                    case 'spam':
                      resultMessage = 'Captcha no correcto.';
                    break;
                  }                      
                }
                
              }
            }
          }
      }, res => {
        console.log(res);
        
      }, () => {
        if(resultError){
          this.vClass = 'error';
        } else {
          this.vClass = 'success';
        }
        
        this.vMessage = resultMessage;
        this.vShow = true;

        setTimeout(() => {
          this.clearAlert();
        }, 6000);
      });

  }


}
