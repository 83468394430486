import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-servicios',
  templateUrl: './page-servicios.component.html',
  styleUrls: ['./page-servicios.component.scss']
})
export class PageServiciosComponent implements OnInit {

  public paramServiceId = '';

  constructor(private activatedRoute: ActivatedRoute,private route: ActivatedRoute) {
 
   
  }

  ngOnInit() {
    window.scroll(0,0);

    this.route.paramMap.subscribe(params => {
      window.scroll(0,0);
      let paramIdGet = params.get("id");
      console.log(paramIdGet);
      
      if(paramIdGet == 'pedagogias' || paramIdGet == 'psicologia' || paramIdGet == 'lactancia'){
        this.paramServiceId = paramIdGet;
      }
    })

    /*let paramId = this.activatedRoute.snapshot.paramMap.get('id');

    if(paramId == 'pedagogias'){
      let element = document.getElementById('scroll-pedagogias').getBoundingClientRect().top;
      if(typeof element !== 'undefined') {
        window.scroll(0,element);
      }
      this.paramServiceId = paramId;
    } 
    if(paramId == 'psicologia'){
      let element = document.getElementById('scroll-psicologia').getBoundingClientRect().top;
      if(typeof element !== 'undefined') {
        window.scroll(0,element);
      }
      this.paramServiceId = paramId;
    } 
    if(paramId == 'lactancia'){
      let element = document.getElementById('scroll-lactancia').getBoundingClientRect().top;
      if(typeof element !== 'undefined') {
        window.scroll(0,element);
      }
      this.paramServiceId = paramId;
    }*/
    
    
    
  }

}
