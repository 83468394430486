import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-la-mirada-del-educador',
  templateUrl: './subpage-la-mirada-del-educador.component.html',
  styleUrls: ['./subpage-la-mirada-del-educador.component.scss']
})
export class SubpageLaMiradaDelEducadorComponent implements OnInit {

  constructor() {
    window.scroll(0,0);
   }

  ngOnInit() {
  }

}
