import { Component, OnInit } from '@angular/core';

const COOKIE_CONSENT = 'kosmoraices_consent_cookies'
const COOKIE_CONSENT_RECAPTCH = 'kosmoraices_consent_recaptcha_cookies'
const COOKIE_CONSENT_EXPIRE_DAYS = 9999

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  public isConsented: boolean = false; 
  public isConsentedRecaptcha: boolean = false;    

  public checkboxCookies = true;
  public checkboxCookiesRecaptcha = true;

  public showCookiesInfo = true;

    constructor() {
        this.isConsented = this.getCookie(COOKIE_CONSENT) === '1';
        this.isConsentedRecaptcha = this.getCookie(COOKIE_CONSENT_RECAPTCH) === '1';

        this.checkboxCookies = this.isConsented;
        this.checkboxCookiesRecaptcha = this.isConsentedRecaptcha;

        if(this.isConsented){
            this.showCookiesInfo = false;
        }
    }

    
    ngOnInit() {

    }

    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    private deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    private consent(isConsent: boolean, e: any = null) {
        if (!isConsent) {
            return this.isConsented;
        } else if (isConsent) {
            this.setCookie(COOKIE_CONSENT, '1', COOKIE_CONSENT_EXPIRE_DAYS);
            this.isConsented = true;
            if(e != null){
              e.preventDefault();
            }
        }
    }

    private consentRecaptcha(isConsent: boolean, e: any = null) {
        if (!isConsent) {
            return this.isConsented;
        } else if (isConsent) {
            this.setCookie(COOKIE_CONSENT_RECAPTCH, '1', COOKIE_CONSENT_EXPIRE_DAYS);
            this.isConsentedRecaptcha = true;
            if(e != null){
              e.preventDefault();
            }
        }
    }


    public consentCookies(){
        this.consent(this.checkboxCookies);
        this.consentRecaptcha(this.checkboxCookiesRecaptcha);     
        this.showCookiesInfo = false;
    }

}



