import { Component, OnInit } from '@angular/core';
import { ReCaptchaService } from 'angular-recaptcha3';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-page-contacto',
  templateUrl: './page-contacto.component.html',
  styleUrls: ['./page-contacto.component.scss']
})
export class PageContactoComponent implements OnInit {

  public errorEmptyInputsContacto = false;
  public errorEmptyInputsVisita = false;

  public checkContactLegal = false;
  public checkVisitaLegal = false;

  public vClass = 'error'; // success | error
  public vShow = false;
  public vMessage = '';

  public formValuesContacto: {
    name: string,
    phone: string,
    email: string,
    message: string
  } = {
    name: '',
    phone: '',
    email: '',
    message: ''
  };
  public formValuesVisita: {
    name: string,
    lastname: string,
    phone: string,
    email: string,
    service: string,
    message: string
  } = {
    name: '',
    lastname: '',
    phone: '',
    email: '',
    service: 'Lactancia',
    message: ''
  };

  constructor(
    private recaptchaService: ReCaptchaService,
    private _http: HttpClient
    ) { }

  ngOnInit() {
    window.scroll(0,0);
  }

  onCaptchaResponse(event){
    //console.log("onCaptcha",event);    
  }

  clearAlert(){
    this.vClass = 'error';
    this.vMessage = '';
    this.vShow = false;
  }

  sendFormContact(){
    if( this.validateFormContacto() ){
      this.errorEmptyInputsContacto = false;
      this.recaptchaService.execute({action: 'sendFormContact'}).then(token => {
        // Backend verification method
        this.sendTokenToBackend(token);
        this.finalSend(this.formValuesContacto,token,'sendFormContact');
        //console.log('formValuesContacto',this.formValuesContacto);
      });
    }else {
      this.errorEmptyInputsContacto = true;
    }
   
  }

  sendFormVisita(){    
    if( this.validateFormVisita() ){
      this.errorEmptyInputsVisita = false;
      this.recaptchaService.execute({action: 'sendFormVisita'}).then(token => {
        // Backend verification method
        this.sendTokenToBackend(token);
        this.finalSend(this.formValuesVisita,token,'sendFormVisita');
        //console.log('formValuesVisita',this.formValuesVisita);
      });
    } else {
      this.errorEmptyInputsVisita = true;
    }
  }

  sendTokenToBackend(token){
    //console.log(token);
    
  }

  validateFormContacto(): boolean{
    let result = false;
    if(
      this.formValuesContacto.email != ''
      && this.formValuesContacto.message != ''
      && this.formValuesContacto.name != ''
      && this.formValuesContacto.phone != ''
    ){
      if(this.checkContactLegal){
        result = true;
      }
    }
    
    return result;
  }

  validateFormVisita(): boolean{
    let result = false;
    if(
      this.formValuesVisita.email != ''
      && this.formValuesVisita.message != ''
      && this.formValuesVisita.name != ''
      && this.formValuesVisita.phone != ''
      && this.formValuesVisita.lastname != ''
      && this.formValuesVisita.service != ''
    ){
      if(this.checkVisitaLegal){
        result = true;
      }
    }
    
    return result;
  }


  finalSend(params,token,action){
    let finalParams = {};
    finalParams['token'] = token;
    finalParams['params'] = params;
    finalParams['action'] = action;


    let paramsHttp = new HttpParams();
    paramsHttp = paramsHttp.append('params', JSON.stringify(finalParams));
    let headers = new HttpHeaders();

    /*this._http.request(
      'post', 'http://localhost/kosmos/kosmos_server/form_submit.php',
      { body: paramsHttp, headers: headers, withCredentials: false });*/

    let resultError = true;
    let resultMessage = 'Ocurrió un error, inténtelo más tarde.';
    //this._http.post('http://localhost/kosmos/kosmos_server/form_submit.php', {token: token, parameters: JSON.stringify( params) } , {})
    this._http.request(
      'post', 'https://api.kosmoraices.com/form_submit.php',
      { body: paramsHttp, headers: headers, withCredentials: true })
      .subscribe( (response: any) => {
        console.log(response);
        if(typeof response !== 'undefined'){
          if(typeof response.error !== 'undefined'){
            if(!response.error){
              resultError = false;
              resultMessage = 'Mensaje enviado correctamente.';
              this.formValuesContacto = {
                name: '',
                phone: '',
                email: '',
                message: ''
              };
              this.formValuesVisita = {
                name: '',
                lastname: '',
                phone: '',
                email: '',
                service: 'Lactancia',
                message: ''
              };
            } else {
              if(typeof response.error_name !== 'undefined'){
                  switch(response.error_name){
                    
                    case 'empty_params':
                      resultMessage = 'Se deben rellenar todos los campos.';

                    break;
                    case 'not_valid_params':
                    case 'empty_params_system':
                    case 'empty_form_type':
                    case 'not_sent':
                      resultMessage = 'Ocurrió un error, inténtelo más tarde.';

                    break;
                    case 'spam':
                      resultMessage = 'Captcha no correcto.';
                    break;

                  }                      
                }
                
              }
            }
          }
      }, res => {
        console.log(res);
        
      }, () => {
        if(resultError){
          this.vClass = 'error';
        } else {
          this.vClass = 'success';
        }
        
        this.vMessage = resultMessage;
        this.vShow = true;

        setTimeout(() => {
          this.clearAlert();
        }, 6000);
      });

  }

}
