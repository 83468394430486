import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { ReCaptchaService } from 'angular-recaptcha3';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-worker',
  templateUrl: './contact-worker.component.html',
  styleUrls: ['./contact-worker.component.scss']
})
export class ContactWorkerComponent implements OnInit {

  public errorEmptyInputsContacto = false;
  public errorEmptyInputsVisita = false;

  public vClass = 'error'; // success | error
  public vShow = false;
  public vMessage = '';

  public subscriber: Subscription = null

  public formValuesContacto: {
    name: string,
    phone: string,
    email: string,
    message: string,
    worker: string
  } = {
    name: '',
    phone: '',
    email: '',
    message: '',
    worker: ''
  };
  
  constructor(
    private recaptchaService: ReCaptchaService,
    private _http: HttpClient,
    private route: ActivatedRoute
  ) {

    


  }

  ngOnInit() {
    window.scroll(0,0);

    

    this.subscriber = this.route.paramMap.subscribe(params => {
      window.scroll(0,0);
      let paramIdGet = params.get("id");
      console.log(paramIdGet);
      
      if(paramIdGet == 'gabriela' 
        || paramIdGet == 'aurora' 
        || paramIdGet == 'elena' 
        //|| paramIdGet == 'esther' 
        || paramIdGet == 'zoraida'){
        this.formValuesContacto.worker = paramIdGet;
        /*if(!this.isready){
          setTimeout(() => {
            this.isready = true;
          }, 2000);
          //this.isready = true;
        }*/
        
      }
    })
  }

  onCaptchaResponse(event){
    //console.log("onCaptcha",event);    
  }

  clearAlert(){
    this.vClass = 'error';
    this.vMessage = '';
    this.vShow = false;
  }

  sendFormContactWorker(){
    if( this.validateFormContacto() ){
      this.errorEmptyInputsContacto = false;
      this.recaptchaService.execute({action: 'sendFormContactWorker'}).then(token => {
        // Backend verification method
        this.sendTokenToBackend(token);
        this.finalSend(this.formValuesContacto,token,'sendFormContactWorker');
        //console.log('formValuesContacto',this.formValuesContacto);
      });
    }else {
      this.errorEmptyInputsContacto = true;
    }
   
  }

  sendTokenToBackend(token){
    //console.log(token);
    
  }

  validateFormContacto(): boolean{
    let result = false;
    if(
      this.formValuesContacto.email != ''
      && this.formValuesContacto.message != ''
      && this.formValuesContacto.name != ''
      && this.formValuesContacto.phone != ''
    ){
      result = true;
    }
    
    return result;
  }

  finalSend(params,token,action){
    let finalParams = {};
    finalParams['token'] = token;
    finalParams['params'] = params;
    finalParams['action'] = action;


    let paramsHttp = new HttpParams();
    paramsHttp = paramsHttp.append('params', JSON.stringify(finalParams));
    let headers = new HttpHeaders();

    /*this._http.request(
      'post', 'http://localhost/kosmos/kosmos_server/form_submit.php',
      { body: paramsHttp, headers: headers, withCredentials: false });*/

    let resultError = true;
    let resultMessage = 'Ocurrió un error, inténtelo más tarde.';
    //this._http.post('http://localhost/kosmos/kosmos_server/form_submit.php', {token: token, parameters: JSON.stringify( params) } , {})
    this._http.request(
      'post', 'https://api.kosmoraices.com/form_submit.php',
      { body: paramsHttp, headers: headers, withCredentials: true })
      .subscribe( (response: any) => {
        console.log(response);
        if(typeof response !== 'undefined'){
          if(typeof response.error !== 'undefined'){
            if(!response.error){
              resultError = false;
              resultMessage = 'Mensaje enviado correctamente.';
              this.formValuesContacto = {
                name: '',
                phone: '',
                email: '',
                message: '',
                worker: this.formValuesContacto.worker
              };
             
            } else {
              if(typeof response.error_name !== 'undefined'){
                  switch(response.error_name){
                    
                    case 'empty_params':
                      resultMessage = 'Se deben rellenar todos los campos.';

                    break;
                    case 'not_valid_params':
                    case 'empty_params_system':
                    case 'empty_form_type':
                    case 'not_sent':
                      resultMessage = 'Ocurrió un error, inténtelo más tarde.';

                    break;
                    case 'spam':
                      resultMessage = 'Captcha no correcto.';
                    break;

                  }                      
                }
                
              }
            }
          }
      }, res => {
        console.log(res);
        
      }, () => {
        if(resultError){
          this.vClass = 'error';
        } else {
          this.vClass = 'success';
        }
        
        this.vMessage = resultMessage;
        this.vShow = true;

        setTimeout(() => {
          this.clearAlert();
        }, 6000);
      });

  }


  ngOnDestroy(){
    this.subscriber.unsubscribe();
    this.subscriber.remove;
    this.subscriber = null;
  }

}
