import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage-acompanamiento-en-grupo',
  templateUrl: './subpage-acompanamiento-en-grupo.component.html',
  styleUrls: ['./subpage-acompanamiento-en-grupo.component.scss']
})
export class SubpageAcompanamientoEnGrupoComponent implements OnInit {

  constructor() {
    window.scroll(0,0);
  }

  ngOnInit() {
  }

}
