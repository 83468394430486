import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subpage-temp-servicio-info',
  templateUrl: './subpage-temp-servicio-info.component.html',
  styleUrls: ['./subpage-temp-servicio-info.component.scss']
})
export class SubpageTempServicioInfoComponent implements OnInit {

  title: string;

  constructor(private route: ActivatedRoute) { 

    window.scroll(0,0);
  }

  ngOnInit() {
    this.title = this.route.snapshot.paramMap.get('title');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
    this.title = this.title.replace('-',' ');
  }

}
