import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kosmo raíces';

  /*allowAccess = false;
  passaccess = '';
  isError = false;

  countersFastPass = 0;
  showTextPass = '';*/

  ngOnInit(){
    /*let storagePass = localStorage.getItem('kosmopass');
    
    if(storagePass != null){
      this.passaccess = storagePass;
      this.checkPass();
    }*/
  }


  /*checkPass(){
    if(this.passaccess == 'KosmoAccess2021'){
      localStorage.setItem('kosmopass', 'KosmoAccess2021');
      this.allowAccess = true;
      this.isError = false;
    } else{
      this.isError = true;
      this.allowAccess = false;
    }
    this.passaccess = '';
  }

  fastPass(){
    this.countersFastPass ++;
    if(this.countersFastPass >= 5){
      this.showTextPass = 'KosmoAccess2021';
    }
  }

  autopass(){
    this.passaccess = this.showTextPass;
  }*/
}
